import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";

import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import HistoryPage from "./pages/HistoryPage";
import SettingsPage from "./pages/SettingsPage";

import AuthContext from "./store/auth-context";

function App() {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(authCtx.isLoggedIn);
  });

  return (
    <div className="App">
      <Routes>
        {authCtx.isLoggedIn && (
          <Route path="/" element={<DashboardPage />}></Route>
        )}

        <Route path="/dashboard" element={<HomePage />}></Route>

        {authCtx.isLoggedIn && (
          <Route path="/history" element={<HistoryPage />}></Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/settings" element={<SettingsPage />}></Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="*" element={<Navigate to="/dashboard" />} />
        )}
        {!authCtx.isLoggedIn && (
          <Route path="*" element={<Navigate to="/auth" />} />
        )}
        {!authCtx.isLoggedIn && (
          <Route path="/auth" element={<AuthPage />}></Route>
        )}
      </Routes>
    </div>
  );
}

export default App;
