import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import AuthContext from "../../store/auth-context";
import "../dashboard/DashboardSidebar.css";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

const DashboardSiderbar = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div className="sidebar">
      <div>
        <img
          className="logo"
          alt="logo"
          src="https://www.termoshop.si/wp-content/uploads/2021/07/termoshop-30-let.png"
        />
      </div>

      <nav>
        <ul>
          {!isLoggedIn && (
            <li className={splitLocation[1] === "auth" ? "active" : ""}>
              <Link to="/auth">Login</Link>
            </li>
          )}
          {isLoggedIn && (
            <li className={splitLocation[1] === "" ? "active" : ""}>
              <DashboardIcon sx={{ color: "white" }} />{" "}
              <Link to="/">Dashboard</Link>
            </li>
          )}
          {isLoggedIn && (
            <li className={splitLocation[1] === "history" ? "active" : ""}>
              <HistoryIcon sx={{ color: "white" }} />{" "}
              <Link to="/history">Zgodovina</Link>
            </li>
          )}
          {isLoggedIn && (
            <li className={splitLocation[1] === "settings" ? "active" : ""}>
              <AccountCircleIcon sx={{ color: "white" }} />{" "}
              <Link to="/settings">Nastavitve</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <LogoutIcon sx={{ color: "white" }} />{" "}
              <button onClick={logoutHandler}>Odjava</button>
            </li>
          )}

          {isLoggedIn && (
            <li style={{marginTop: "30px"}}>
               <PrecisionManufacturingIcon sx={{ color: "white" }} />{" "}
               <Link to="/dashboard">Proizvodnja</Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};
export default DashboardSiderbar;
