import { useState, useRef, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

import CircularProgress from "@mui/material/CircularProgress";

import classes from "./AuthForm.module.css";
import "../../App.css";

const AuthForm = () => {
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitHandler = (event) => {
    console.log("click");
    event.preventDefault();

    const enteredUsername = usernameInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    console.log(enteredPassword);
    console.log(enteredUsername);

    setIsLoading(true);

    fetch("https://proizvodnja.termo-plus.eu/api/users/authenticate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: enteredUsername,
        password: enteredPassword,
      }),
    })
      .then((response) => response.json())
      .then(async (json) => {
        if (json.success === true) {
          setError("");
          setIsLoading(false);
          const expirationTime = new Date(
            new Date().getTime() + json.expiresIn * 1000
          );
          authCtx.login(json.token, expirationTime);
          navigate("/");
        } else {
          setError("Napačno uporabniško ime ali geslo");
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }
      })
      .catch((error) => {
        alert("Server error");
        console.error(error);
      });
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <section className={classes.auth}>
        <div>
          <img
            className="logo"
            alt="logo"
            src="https://www.termoshop.si/wp-content/uploads/2021/07/termoshop-30-let.png"
          />
        </div>
        <Card style={{ padding: "20px" }}>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            onSubmit={submitHandler}
          >
            <div className={classes.control}>
              <TextField
                id="username"
                label="Uporabniško ime"
                required
                placeholder="Vpiši uporabniško ime"
                inputRef={usernameInputRef}
                style={{ width: "100%" }}
              />
            </div>
            <div className={classes.control}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Geslo
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  required
                  style={{ width: "100%" }}
                  inputRef={passwordInputRef}
                  placeholder="Vpiši svoje geslo"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
            <div className={classes.actions}>
              {error !== "" && (
                <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
              )}
              {isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Button
                className="btn-primary"
                variant="contained"
                style={{ width: "100%", height: "56px", fontWeight: "600" }}
                type="submit"
              >
                Prijava
              </Button>
            </div>
          </Box>
        </Card>
      </section>
    </Container>
  );
};

export default AuthForm;
