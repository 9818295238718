import "./History.css";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Moment from "moment";
import "moment/locale/sl";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnitsHistory } from "../../store/redux/slices/unitsSlice";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";

const History = () => {
  const [unit, setUnit] = useState(null);

  const units = useSelector((state) => state.units.unitsHistory);
  const unitStatus = useSelector((state) => state.units.historyStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (unitStatus === "idle") {
      dispatch(fetchUnitsHistory());
    }
  }, [dispatch, unitStatus]);

  function getStartTime(params) {
    Moment.locale("sl");

    return Moment(params.row.phases[0].startTime).format(
      "MMMM Do YYYY, HH:mm:ss"
    );
  }

  function getEndTime(params) {
    Moment.locale("sl");

    return Moment(params.row.phases[0].endTime).format(
      "MMMM Do YYYY, HH:mm:ss"
    );
  }

  const getWorkplace = (workplace) => {
    var dm = "";

    if (workplace === 1 || workplace === 2) {
      dm = "DM1";
    } else if (workplace === 3 || workplace === 4) {
      dm = "DM2";
    } else if (workplace === 5 || workplace === 6) {
      dm = "DM3";
    } else if (workplace === 7 || workplace === 8) {
      dm = "DM4";
    } else if (workplace === 9 || workplace === 10) {
      dm = "DM5";
    }

    return dm;
  };

  const handleRowClick = (params) => {
    units.map((unit) => {
      if (unit.sn === params.row.sn) {
        console.log(unit);
        setUnit(unit);
      }
    });
    
  };

  const columns = [
    {
      field: "enota",
      headerName: "Enota",
      width: 350,
    },
    {
      field: "sn",
      headerName: "Serijska številka",
      width: 150,
    },
    {
      field: "dn",
      headerName: "Delovni nalog",
      width: 150,
    },
    {
      field: "startTime",
      headerName: "Začetni čas",
      width: 250,
      valueGetter: getStartTime,
    },
    {
      field: "endTime",
      headerName: "Končni čas",
      width: 250,
      valueGetter: getEndTime,
    },
  ];

  const closeData = () => {
    //setPhases([]);
    setUnit(null);
  };

  return (
    <div className="content">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {unit !== null && (
            <Card className="card">
              <CardContent className="card-content">
                <div className="row between">
                  <div>
                    <h3 className="card-title">
                      <strong>{unit.enota}</strong>
                    </h3>
                    <h4 className="card-subtitle">
                      {unit.dn}/{unit.sn}
                    </h4>
                  </div>
                  <div className="row">
                    <CloseIcon className="btn" onClick={closeData} />
                  </div>
                </div>
              </CardContent>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="bg-grey">
                    <TableRow>
                      <TableCell>
                        <strong>Delovno mesto</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Začetni čas</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Končni čas</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Opomba</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {unit.phases.map((row) => (
                      <TableRow
                        key={row.workplace}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {getWorkplace(row.workplace)}
                        </TableCell>
                        <TableCell>
                          {Moment(row.startTime).format(
                            "Do MMMM YYYY, HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>
                          {Moment(row.endTime).format("Do MMMM YYYY, HH:mm:ss")}
                        </TableCell>
                        <TableCell>{unit.note ? unit.note : "/"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card className="card">
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={units}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                getRowId={(row) => row._id + 1}
                experimentalFeatures={{ newEditingApi: true }}
                onRowClick={handleRowClick}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default History;
