import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  timeSettings: [],
  planSettings: [],
  status: "idle",
  error: null,
};

const API_URL = "https://proizvodnja.termo-plus.eu/api";

export const fetchPlanSettings = createAsyncThunk(
  "settings/fetchPlanSettings",
  async () => {
    const response = await axios.get(`${API_URL}/settings/plan-settings`);
    return response.data;
  }
);

export const updatePlanSettings = createAsyncThunk("settings/updatePlanSettings", async (data) => {
  const response = await axios.post(`${API_URL}/settings/plan-settings`, data);
  return response.data;
})

export const fetchTimeSettings = createAsyncThunk(
  "settings/fetchTimeSettings",
  async () => {
    const response = await axios.get(`${API_URL}/settings/time-settings`);
    return response.data;
  }
);

export const updateTimeSettings = createAsyncThunk(
  "settings/updateTimeSettings",
  async (data) => {
    const response = await axios.post(`${API_URL}/settings/time-settings`, data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTimeSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.timeSettings = state.timeSettings.concat(action.payload);
      })
      .addCase(fetchPlanSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.planSettings = state.planSettings.concat(action.payload);
      })
      .addCase(updateTimeSettings.fulfilled, (state, action) => {
        state.timeSettings.map((setting) => {
          if (setting.dm === action.meta.arg.dm) {
            setting.time = action.meta.arg.time;
          }
        });
      })
      .addCase(updatePlanSettings.fulfilled, (state, action) => {
        state.planSettings.map((setting) => {
          if (setting.name === action.meta.arg.name) {
            setting.plan = action.meta.arg.plan;
          }
        });
      });
  },
});

export const { settingUpdated } = settingsSlice.actions;
export default settingsSlice.reducer;
export const selectAllSettings = (state) => state.settings.settings;
