import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  units: [],
  unitsHistory: [],
  todayCompleted: 0,
  weekCompleted: 0,
  status: "idle",
  historyStatus: "idle",
  error: null,
};

const API_URL = "https://proizvodnja.termo-plus.eu/api";

export const fetchActiveUnits = createAsyncThunk(
  "units/fetchActiveUnits",
  async () => {
    const response = await axios.get(`${API_URL}/stages/getAllStages`);
    return response.data;
  }
);

export const fetchUnitsHistory = createAsyncThunk(
  "units/fetchUnitsHistory",
  async () => {
    const response = await axios.get(`${API_URL}/stages/getAllCompletedStages`);
    return response.data;
  }
);

export const fetchCompletedUnitsToday = createAsyncThunk("units/fetchCompletedUnitsToday", async() => {
  const response = await axios(`${API_URL}/stages/getTodayCompleted`);
  return response.data;
})

export const fetchCompletedUnitsWeek = createAsyncThunk("units/fetchCompletedUnitsWeek", async() => {
  const response = await axios(`${API_URL}/stages/getWeekCompleted`);
  return response.data;
})

export const addNewUnit = createAsyncThunk(
  "units/addNewUnit",
  async (initialUnit) => {
    const response = await axios.post(`${API_URL}/stages/addSerial`, initialUnit);
    return response.data;
  }
);

export const updateUnit = createAsyncThunk("units/updateUnit", async (data) => {
  const response = await axios.post(`${API_URL}/stages/updatePhase`, data);
  return response.data;
});

export const addNewPhase = createAsyncThunk(
  "units/addNewPhase",
  async (data) => {
    const response = await axios.post("/stages/addPhase", data);
    return response.data;
  }
);

export const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    removeUnit: (state, action) => {
      const { id } = action.payload;
      let index = state.units.findIndex(({ id }) => id === action.payload.id);
      state.units.splice(index, 1);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActiveUnits.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchActiveUnits.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.units = state.units.concat(action.payload);
      })
      .addCase(fetchActiveUnits.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUnitsHistory.pending, (state, action) => {
        state.historyStatus = "loading";
      })
      .addCase(fetchUnitsHistory.fulfilled, (state, action) => {
        state.historyStatus = "succeeded";
        state.unitsHistory = state.unitsHistory.concat(action.payload);
        console.log(state.unitsHistory);
      })
      .addCase(fetchUnitsHistory.rejected, (state, action) => {
        state.historyStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewUnit.fulfilled, (state, action) => {
        state.units.push(action.payload);
      })
      .addCase(updateUnit.fulfilled, (state, action) => {
        state.units.map((unit) => {
          if (unit._id === action.payload._id) {
            unit.isActive = action.meta.arg.isActive;
            unit.currentWorkplace = action.meta.arg.workplace;
            unit.phases.map((phase) => {
              if (phase.endTime == null) {
                phase.endTime = action.meta.arg.endTime;
                phase.workplace = action.meta.arg.workplace;
              }
            });
          }
        });
      })
      .addCase(addNewPhase.fulfilled, (state, action) => {
        state.units.map((unit) => {
          if (unit._id == action.payload._id) {
            unit.currentWorkplace = action.meta.arg.workplace;
            unit.phases.push({
              workplace: action.meta.arg.workplace,
              startTime: action.meta.arg.startTime,
              endTime: null,
            });
          }
        });
      })
      .addCase(fetchCompletedUnitsToday.fulfilled, (state, action) => {
        state.todayCompleted = action.payload.length;
      })
      .addCase(fetchCompletedUnitsWeek.fulfilled, (state, action) => {
        state.weekCompleted = action.payload.length;
      });
  },
});

export const { unitAdded, unitUpdated, removeUnit } = unitsSlice.actions;

export default unitsSlice.reducer;

export const selectAllUnits = (state) => state.units.units;
export const selectAllUnitsHistroy = (state) => state.units.unitsHistory;

export const selectUnitBySn = (state, unitSn) =>
  state.units.units.find((unit) => unit.sn === unitSn);

export const selectUnitFromHistoryBySn = (state, unitSn) =>
  state.units.unitsHistory.find((unit) => unit.sn === unitSn);
