import Layout from "../components/Layout/Layout";
import Settings from "../components/settings/Settings";

const SettingsPage = () => {
  return (
    <Layout>
      <div>
        <Settings />
      </div>
    </Layout>
  );
};

export default SettingsPage;
