import { configureStore } from "@reduxjs/toolkit";
import unitsReducer from "./slices/unitsSlice";
import settingsReducer from "./slices/settingsSlice";

const store = configureStore({
  reducer: { units: unitsReducer, settings: settingsReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
